import Image from 'next/image';
import Link from 'next/link';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NotFoundSection from "../../NotFoundSection";
// Cat Images
import art from "../../../public/category/art-nft-icon.webp";
import collectibles from "../../../public/category/collectibles.webp";
import domain from "../../../public/category/domain.webp";
import games from "../../../public/category/games.webp";
import memes from "../../../public/category/memes.webp";
import music from "../../../public/category/music.webp";
import photography from "../../../public/category/photography.webp";
import sports from "../../../public/category/sports.webp";
import trading_card from "../../../public/category/trading_card.webp";
import others from "../../../public/category/others.webp";
import videos from "../../../public/category/videos.webp";
import { withTranslation } from 'next-i18next';
import { apiHelperBlockchain } from "../../../helper/apiHelper";
// styles
import styles from "./BrowseCat.module.scss";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
class BrowseCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
            catLoader: true
        }
    }

    componentDidMount =  () => {
        this.getAllCategory();
    }

    getAllCategory =  () => {
        this.setState({ catLoader: true });
        apiHelperBlockchain('category-list', 'GET').then(resp => {
            this.setState({ catLoader: false });
            if (resp.data.status) {
                this.setState({ categoryList: resp.data.data });
            }
        }).catch(error => console.error(`Error: ${error}`));
    }

    render() {
        const { t } = this.props;
        const { categoryList } = this.state;

        return (
            <div className={`section ${styles.browseCatSec}`}>
                <Container>
                    <h2 className={`h3 ${styles.h3}`}>{t('common:browse_category')}</h2>
                    <Row className={styles.list}>
                        {this.state.catLoader ? 
                            <ShimmerSimpleGallery imageHeight={60} row={3} col={4} gap={20}  />
                             : categoryList && categoryList.length > 0 ?
                            <>
                                {
                                    categoryList && categoryList.map((item, idx) => {
                                        return (
                                            <Col sm={6} md={4} xl={3} key={idx} className={styles.boxList}>
                                                <Link href={`/nft/category/${encodeURI(item.page_name)}`} key={idx}>
                                                    <a className={styles.browseCat}>
                                                        <div className="d-flex align-items-center">
                                                            <div className={styles.category_img}>
                                                                {(() => {
                                                                    if (item._id === "625a4a38dc9e3588e3211e67") { return (<Image src={art} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "625a4a66dc9e3588e3211e6b") { return (<Image src={music} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "625a4a6cdc9e3588e3211e6d") { return (<Image src={sports} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2ae7550d7001c551c977") { return (<Image src={videos} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2af0a99603bc742a6016") { return (<Image src={domain} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2b0aa99603bc742a6018") { return (<Image src={photography} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2b12a99603bc742a601e") { return (<Image src={memes} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2b17550d7001c551c986") { return (<Image src={games} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2b2eca0308869ffcb320") { return (<Image src={collectibles} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "627a2b36ca0308869ffcb322") { return (<Image src={trading_card} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                    else if (item._id === "628c9b2b0e9064f2749f96a2") { return (<Image src={others} alt={item.category_name} width="56" height="56" layout='responsive' loading='lazy' />) }
                                                                })()}
                                                            </div>

                                                            <span className={`ms-3 ${styles.category_text}`}>
                                                                {
                                                                    this.props.currLocale === "en" ? (item.category_name).charAt(0).toUpperCase() + (item.category_name).slice(1)
                                                                        :
                                                                        item.category_name_arabic
                                                                }
                                                            </span>
                                                        </div>
                                                    </a>
                                                </Link>
                                            </Col>
                                        )
                                    })
                                }
                            </>
                            :
                            <NotFoundSection />
                            
                        }
                    </Row>
                </Container>
            </div>
        )
    }
}
export default withTranslation()(BrowseCategory);
